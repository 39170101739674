import React from 'react';
import { BrowserView, MobileView, TabletView } from 'react-device-detect';
import PartnerMainMobileComponent from '../../components/partner/partner.main.mobile.component';
import { Helmet } from 'react-helmet';
import PartnerMainWebComponent from '../../components/partner/partner.main.web.component';
import PartnerMainTabletComponent from '../../components/partner/partner.main.tablet.component';


const PartnerMain = ({...props}) : React.ReactElement => {

    return (
        <>
            <Helmet>
                <title>우리 주변의 펫, 우주펫 파트너</title>
                <meta name="우리 주변의 펫, 우주펫 파트너" content="우리동네 반려동물 돌봄 커뮤니티"/>
                <meta name="description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우리 주변의 펫, 우주펫 파트너"/>
                <meta property="og:url" content="https://spacepet.site/partner"/>
                <meta property="og:description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="우리 주변의 펫, 우주펫 파트너"/>
            </Helmet>

            <div style={{overflow : "hidden"}}>
                <BrowserView>
                    <PartnerMainTabletComponent/>
                </BrowserView>
            
                <TabletView>
                    <PartnerMainWebComponent/>
                </TabletView>

                <MobileView>
                    <PartnerMainMobileComponent/>
                </MobileView>
            </div>
        </>

    )
}

export default PartnerMain