import React from 'react';
import TitleLogo from '../../assets/icon/partner/logo.svg';
import MenuIcon from '../../assets/icon/partner/menu.svg';
import './partner.styles.css'
import { useNavigate } from 'react-router-dom';

const PartnerHeader = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const onMenu = () => {
        navigate("/partner/menu");
    }

    return <div className="header">
        <img src={TitleLogo} alt="우주펫 파트너" className="headerLogo"/>
        <img src={MenuIcon} alt="메뉴" className="menu" onClick={() => onMenu()}/>
    </div>
}


export default PartnerHeader;