import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';

const Component05 = ({...props}) : React.ReactElement => {

   
    return (
        <> 

            <div style={{height : "6.8966vh"}}/>

            <p className="grdientTxt02">
                사장님들의 시간을 절약하는
            </p>

            <div style={{height : "1.7241vh"}}/>

            <p className="titleTxt02">
                자동 알림톡 발송
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p className="contentTxt">
                그동안 고객님에게 직접 연락하셨나요?<br/>
                우주펫에 예약 등록하면 사장님 대신<br/>
                자동으로 알림톡을 보내드려요.
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_005.png`} alt="" className="componentImg"/>
           
            
        </>

    );
}

export default Component05;