import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';


const Component02 = ({...props}) : React.ReactElement => {


    return <div className="gradientCon">

        <div style={{height : 95}}/>

        <div className="componentCon">
            <p className="webColorTitleTxt"> 
                우주펫 파트너가 되면
            </p>
        </div>

        <div style={{height : 20}}/>

        <div className="componentCon">
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p className="webColorSubTitleTxt"> 
                    우주펫에서만 할 수 있는
                </p> 
                <div style={{height : 6}}/>
                <p className="webTitleTxt"> 
                    반려인에게 직접 제안
                </p>                
                <div style={{height : 7}}/>
                <p className="webSubTitleTxt"> 
                    돌봄 요청한 고객에게 직접 제안하세요!<br/>
                    파트너는 돌봄이 필요한 반려인에게<br/>
                    먼저 돌봄을 제안할 수 있어요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_002.png`} className="componentImage" alt=""/>
        </div>
    
        <div style={{height : 42}}/>

    </div>
}

export default Component02;