import React from 'react';
import '../common/common.styles.css';

import NaverBlog from '../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../assets/icon/component/footer/instagram.svg';
import { Link } from 'react-router-dom';

const Footer = ({...props}) : React.ReactElement => {

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }

     return (
        <div className='footer2'>

            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", marginTop: 0, marginBottom : 60}}>
                <a className='footerSemiBoldTitle' href="mailto:sungsoo@spacepet.info">제휴/IR문의</a>

                <div className='footerBottomCon'>
                    <img src={NaverBlog} alt="" className="NaverBlogButton" onClick={() => pressNaverBlog()}/>
                    <img src={Instagram} alt="" onClick={() => pressInstagram()}/>
                </div>
            </div>

            <p className='footerCompanyTitle'>주식회사 우주펫</p>
            
            <div className='footerBottomCon_01'>

                <div className='footerTextCon'>                    

                    <div className='footerSemiTextCon'>
                        <p className='footerSemiTitle'>대표 : 박성수</p>
                        <div className='verticalDivider' />
                        <p className='footerSemiTitle'>사업자등록번호 : 860-81-02850</p>
                    </div>

                    <div className='footerSemiTextCon'>
                        <div className='verticalDivider' />
                        <p className='footerSemiTitle'>주소 : 서울특별시 강남구 역삼로 169 (역삼동, 명우 빌딩)</p>
                    </div>
                </div>                
            </div>


            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", marginTop: 0, marginBottom : 0}}>
                <div className='footerSemiTextCon'>
                    <p className='footerSemiTitle'>​‌고객센터 : 010 - 2276 - 0834</p>
                    <div className='verticalDivider' />
                    <p className='footerSemiTitle'>이메일 : spacepetofficial@gmail.com</p>
                </div>

                <div className='footerSemiTextCon'>
                    <p className='footerSemiBoldTitle' onClick={() => onTerm()} style={{marginLeft : 10, cursor : "pointer"}}>​이용약관</p>
                    <div style={{width : "0.6250vw", cursor : "pointer"}} onClick={() => onPrivacyTerm()}/><p className='footerSemiBoldTitle'>개인정보처리방침</p>
                </div>
            </div>           
            
            <div className='bottomCon'>
                <p className='footerTitleClc'>​spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
            </div>

        </div>
    )
}

export default Footer;