import React from "react";
import '../partner.styles.css';

import NaverBlog from '../../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../../assets/icon/component/footer/instagram.svg';

const Footer = ({...props}) : React.ReactElement => {

    const onIR = () => {
        window.open("mailto:sungsoo@spacepet.info", "_blank")
    }

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }


    return <div className="footerCon01">
        
        <div className="footerRowCon01">
            <p className="footerTxt01" onClick={() => onIR()}>제휴/IR문의</p>

            <div className="footerRowCon02">
                <div className="footerIconCon" onClick={() => pressNaverBlog()}>
                    <img src={NaverBlog} alt="" className="fotterIcon" />
                </div>

                <div className="footerIconCon" onClick={() => pressInstagram()}>
                    <img src={Instagram} alt="" className="fotterIcon" />
                </div>
            </div>
        </div>

        <div style={{height : 44}}/>

        <p className="footerTxt02">주식회사 우주펫</p>

        <div style={{height : 20}}/>

        <p className="footerTxt03">대표 : 박성수 | 사업자등록번호 : 860-81-02850</p>

        <div style={{height : 12}}/>

        <p className="footerTxt03">통신판매업신고번호 : 2020-서울강남-01433</p>

        <div style={{height : 12}}/>

        <p className="footerTxt03">주소 : 서울특별시 강남구 역삼로165</p>

        <div style={{height : 12}}/>

        <p className="footerTxt03">​‌고객센터 : 010. 2276. 0834</p>

        <div style={{height : 12}}/>

        <div className="footerRowCon04">
            <p className="footerTxt03">이메일 : spacepetofficial@gmail.com</p>

            <div className="footerRowCon03">
                <p className="footerTxt04" onClick={() => onTerm()}>이용약관</p>
                <div style={{width : 16}}/>
                <p className="footerTxt04" onClick={() => onPrivacyTerm()}>개인정보처리방침</p>
                <div style={{width : 16}}/>
                <p className="footerTxt04" onClick={() => onGeometryTerm()}>위치기반 서비스 약관</p>
            </div>
        </div>

        <div style={{height : 66}}/>


        <p className="footerTxt05">spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
       


    </div>
}

export default Footer;