import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import './partner.styles.css'

import TitleLogo from '../../assets/icon/partner/logo.svg';

const PartnerWebHeader = ({...props}) : React.ReactElement => {

    const onStart = () => {
        if (isIOS) {
            window.open("https://apps.apple.com/kr/app/%EC%9A%B0%EC%A3%BC%ED%8E%AB-%ED%8C%8C%ED%8A%B8%EB%84%88/id6673888931?uo=4&at=11l6hc&ct=fnd");
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.universepet.universepetPartners.universepet_partners");
        }
    }

    return <div className="header">
        <img src={TitleLogo} alt="우주펫 파트너" className="headerLogo"/>
        <div className="headerBtn" onClick={() => onStart()}>
            <p className="headerBtnTxt">파트너 시작하기</p>
        </div>
    </div>
}


export default PartnerWebHeader;