import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';

import Logo from '../../../assets/icon/common/logo_color.svg';
import { useNavigate } from "react-router-dom";


const Component01 = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const onMain = () => {
        navigate("/");
    }

    const onSitter = () => {
        navigate("/sitter");
    }

    return <div className="webScreen">

        <div className="component01Header">
            <img src={Logo} alt="" className="component01Logo" />

            <div className="component01RowCon">
                <div className="component01Btn" onClick={() => onMain()}>
                    <p className="component01Txt">
                        우주펫 소개
                    </p>
                </div>

                <div style={{width : 10}}/>

                <div className="component01Btn" onClick={() => onSitter()}>
                    <p className="component01Txt">
                        이웃시터 지원하기
                    </p>
                </div>
            </div>
        </div>

        <div style={{height : 7}}/>
      
        <div className="componentCon">
            <div style={{display : "flex", flexDirection : "column", marginTop : 110}}>
                <p className="webTitleTxt"> 
                    펫호텔, 유치원을 찾는<br/>
                    동네 반려인 여기 있어요!
                </p>                
                <div style={{height : 7}}/>
                <p className="webSubTitleTxt"> 
                    언제까지 고객을 기다려야 하나요?<br/>
                    이제 도움이 필요한 반려인을 직접 찾아보세요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_001.png`} className="image01" alt=""/>
        </div>
    </div>
}

export default Component01;