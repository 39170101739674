import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {  Navigation } from "swiper";

import './swiper.styles.css'
import "swiper/css";
import "swiper/css/pagination";
import '../partner.styles.css';
import { CDN } from "../../../keys";

const Component01 = ({...props}) : React.ReactElement => {
    
    const [activeIndex, setActiveIndex] = React.useState(0);


    return (
        <> 

            <div style={{height : "10.7448vh"}}/>

            <p className="titleTxt01">
                펫호텔,유치원을 찾는<br/>
                동네 반려인 여기 있어요!
            </p>

            <div style={{height : "1.8750vw"}}/>

            <p className="contentTxt">
                언제까지 고객을 기다려야 하나요?<br/>
                이제 도움이 필요한 반려인을 직접 찾아보세요.
            </p>

            <div style={{height : "3vh"}}/>

            <Swiper
                slidesPerView={1.5}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}                
                modules={[Navigation]}
                className="mySwiper"
                loop={true}                
                onActiveIndexChange={(value) => {
                    setActiveIndex(value.activeIndex % 9);
                }}                
            >
                <SwiperSlide >
                    <img src={`${CDN}web/partner/swiper-001.png`} alt="" className={(activeIndex === 2)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-002.png`} alt="" className={(activeIndex === 3)? "selectedItem" : "unSelectedItem"}/>             
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-003.png`} alt="" className={(activeIndex === 4)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-004.png`} alt="" className={(activeIndex === 5)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-005.png`} alt="" className={(activeIndex === 6)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-006.png`} alt="" className={(activeIndex === 7)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-007.png`} alt="" className={(activeIndex === 8)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-008.png`} alt="" className={(activeIndex === 0)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={`${CDN}web/partner/swiper-009.png`} alt="" className={(activeIndex === 1)? "selectedItem" : "unSelectedItem"}/>
                </SwiperSlide>

            </Swiper>
        </>

    );
}

export default Component01;