import React from 'react';
import MenuComponent from '../../components/partner/menu/menu.component';

const PartnerMenu = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflow : "hidden"}}>
            <MenuComponent />
        </div>
    )
}

export default PartnerMenu