import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';


const Component05 = ({...props}) : React.ReactElement => {


    return <div style={{background : "#F9FAFF"}}>

        <div style={{height : 72}}/>

        <div className="componentCon">
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p className="webColorSubTitleTxt"> 
                    사장님들의 시간을 절약하는
                </p> 
                <div style={{height : 6}}/>
                <p className="webTitleTxt"> 
                    자동 알림톡 발송
                </p>                
                <div style={{height : 7}}/>
                <p className="webSubTitleTxt"> 
                    그동안 고객님에게 직접 연락하셨나요?<br/>
                    우주펫에 예약 등록하면 사장님 대신<br/>
                    자동으로 알림톡을 보내드려요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_005.png`} className="componentImage" alt=""/>
        </div>
    
        <div style={{height : 72}}/>

    </div>
}

export default Component05;