import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';


const Component03 = ({...props}) : React.ReactElement => {


    return <div style={{background : "#F9FAFF"}}>

        <div style={{height : 72}}/>

        <div className="componentCon">
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p className="webColorSubTitleTxt"> 
                    파트너를 위한 혜택
                </p> 
                <div style={{height : 6}}/>
                <p className="webTitleTxt"> 
                    우리 매장 상단 노출
                </p>                
                <div style={{height : 7}}/>
                <p className="webSubTitleTxt"> 
                    파트너에 가입한 매장은 반려인들에게<br/>
                    내 매장을 가장 상단에 보여줄 수 있어요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_003.png`} className="componentImage" alt=""/>
        </div>
    
        <div style={{height : 72}}/>

    </div>
}

export default Component03;