import React from "react";
import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';

import '../partner.styles.css';


const Component06 = ({...props}) : React.ReactElement => {

    const onButton = () => {
        window.open("/spacepet_partner_info.pdf")
    }
   
    return (
        <div className="componentIntro">    

            <p className="componentIntroTitleTxt">
                우주펫 소개
            </p>

            <div style={{height : "6.8966vh"}}/>

            <div className="subIntroCon">
                <img src={Icon01} alt="" style={{width: "16.8219vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p className="introTxt">
                    펫 돌봄 어플 월 평균 이용자 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p className="introContentTxt">
                    1위
                </p>
            
            </div>

            <div style={{height : "10.3448vh"}}/>

            <div className="subIntroCon">
                <img src={Icon02} alt="" style={{width: "20.3125vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p className="introTxt">
                    반려인 정회원 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p className="introContentTxt">
                    약 50,000명
                </p>
            </div>
            
            <div style={{height : "10.3448vh"}}/>

            <div className="subIntroCon">
                <img src={Icon03} alt="" style={{width: "16.8219vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p className="introTxt">
                    월간 돌봄 신청 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p className="introContentTxt">
                    약 1,000건
                </p>
            </div>

            <div style={{height : "5.1724vh"}}/>

            <p className="introSemiTxt">
                * 24년 9월 기준
            </p>            

            <div style={{height : "10.3448vh"}}/>

            <p className="introSemiTxt02">
                우주펫 파트너에 대한 정보를<br/>더 알고 싶으신가요?
            </p>

            <div style={{height : "3.4483vh"}}/>

            <div className="component06Btn" onClick={() => onButton()}>
                소개서 읽어보기
            </div>


        </div>

    );
}

export default Component06;