import React from 'react';
import './mobile.styles.css'

import NaverBlog from '../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../assets/icon/component/footer/instagram.svg';
import { useNavigate } from 'react-router-dom';

const ContentFooterComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }

    const pressSitter = () => {
        navigate("/sitter")
    }

    const pressMain = () => {
        navigate("/")
    }



    return (
        <div className="homeFooterCon">

            <div className="footerCon">
                <div className="footerBtnCon">
                    <img src={NaverBlog} className="footerBtn" alt="" onClick={() => pressNaverBlog()} />
                    <img src={Instagram} className="footerBtn" alt="" onClick={() => pressInstagram()} />
                </div>

                <div className="footerTxtCon">
                    <span className="footerText02" onClick={() => pressMain()}>우주펫 소개</span>
                    <div style={{width : "10px"}}></div>
                    <span className="footerText02"onClick={() => pressSitter()}>이웃시터 소개</span>
                </div>
            </div>


            <a className="footerBtn01" href="mailto:sungsoo@spacepet.info"><p className="footerBtn02">제휴/IR문의</p></a>
            
            {/* 
            <div className="footerBtnCon">
                <Link to="/term" style={{textDecoration : "none"}}><p className="footerBtn04">이용약관</p></Link>
                <Link to="/privacy" style={{textDecoration : "none"}}><p className="footerBtn04">개인정보처리방침</p></Link>
            </div> */}

            <p className="footerText01">주식회사 우주펫</p>

            <div style={{height : "3vw"}}/>

            <div className="footerTextCon">
                <p className="footerContentTxt">대표 :&nbsp;</p>
                <p className="footerContentTxt"> 박성수</p>
                <div className="footerVertDivider"/>
                <p className="footerContentTxt">사업자등록번호 :&nbsp;</p>
                <p className="footerContentTxt">860-81-02850</p>
            </div>

            <div className="footerTextCon1">
                <p className="footerContentTxt">주소 :&nbsp;</p>
                <p className="footerContentTxt">서울특별시 강남구 역삼로 169 (역삼동, 명우빌딩)</p>
            </div>

            <div className="footerTextCon">
                <p className="footerContentTxt">고객센터 :&nbsp;</p>
                <p className="footerContentTxt">010.2276.0834</p>
            </div>

            <div className="footerTextCon">
                <p className="footerContentTxt">이메일 :&nbsp;</p>
                <p className="footerContentTxt">spacepetofficial@gmail.com</p>
            </div>

            <div style={{height : "12.5000vw"}}/>

            <div className="footerTextCon">
                <p className="footerTextBtn" onClick={() => onTerm()}>이용약관&nbsp;&nbsp;</p>
                <p className="footerTextBtn" onClick={() => onPrivacyTerm()}>개인정보처리방침&nbsp;&nbsp;</p>
                <p className="footerTextBtn" onClick={() => onGeometryTerm()}>위치기반 서비스 약관&nbsp;&nbsp;</p>
            </div>

            <p className="footerClcTxt">spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
            
            <div style={{height : "12.5000vw"}}/>

        </div>
    )
}

export default ContentFooterComponent