import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';

const Component02 = ({...props}) : React.ReactElement => {

   
    return (
        <> 

            <div className="grdientDiv">
                <p className="grdientTxt01">
                    우주펫 파트너가 되면
                </p>

                <div style={{height : "7.9310vh"}}/>

                <p className="grdientTxt02">
                    우주펫에서만 할 수 있는
                </p>
            </div>

            <p className="titleTxt02">
                반려인에게 직접 제안
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p className="contentTxt">
                돌봄 요청한 고객에게 직접 제안하세요!<br/>
                파트너는 돌봄이 필요한 반려인에게<br/>
                먼저 돌봄을 제안할 수 있어요
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_002.png`} alt="" className="componentImg"/>
            
            
        </>

    );
}

export default Component02;