import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';

const Component04 = ({...props}) : React.ReactElement => {

   
    return (
        <> 

            <div style={{height : "6.8966vh"}}/>

            <p className="grdientTxt02">
                반려인들이 이야기한
            </p>

            <div style={{height : "1.7241vh"}}/>

            <p className="titleTxt02">
                신뢰도 상승
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p className="contentTxt">
                파트너 가입시 인증 뱃지를 지급해 드려요.<br/>
                반려인들은 인증 뱃지가 있는 매장을<br/>
                더 신뢰하고 있어요.
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_004.png`} alt="" className="componentImg"/>
           
            
        </>

    );
}

export default Component04;