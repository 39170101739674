import React from "react";
import '../partner.styles.css';

import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';

const Component06 = ({...props}) : React.ReactElement => {

    const onButton = () => {
        window.open("/spacepet_partner_info.pdf")
    }

    return <div>
        <div className="com6Con">
            <p className="webTitleTxt"> 
                우주펫 소개
            </p>    
        </div>

        <div className="com6ColorCon">

            <div className="com6IconRowCon">

                <div className="com6IconCon">
                    <img src={Icon01} alt="" className="com6Icon" />
                
                    <div style={{height : 30}}/>
                    <p className="com6infoTxt"> 
                        펫 돌봄 어플 월 평균 이용자 수
                    </p>
                    <div style={{height : 6}}/>
                    <p className="webTitleTxt"> 
                        1위
                    </p>    
                
                </div>

                <div className="com6IconCon">
                    <img src={Icon02} alt="" className="com6Icon" />

                    <div style={{height : 30}}/>

                    <p className="com6infoTxt"> 
                        반려인 정회원 수
                    </p>

                    <div style={{height : 6}}/>

                    <p className="webTitleTxt"> 
                        약 50,000명
                    </p>  
                </div>

                <div className="com6IconCon">
                    <img src={Icon03} alt="" className="com6Icon" />
                    
                    <div style={{height : 30}}/>

                    <p className="com6infoTxt"> 
                        월간 돌봄 신청 수
                    </p>

                    <div style={{height : 6}}/>

                    <p className="webTitleTxt"> 
                        약 1,000건
                    </p> 
                </div>
            </div>

            <div style={{height : 40}}/>

            <p className="com6InfoTxt">* 24년 9월 기준</p>

            <div style={{height : 38}}/>

            <div className="com6BtnRowCon">
                <p className="webTitleTxt"> 
                    우주펫 파트너에 대한 정보를 더 알고 싶으신가요?
                </p> 

                <div className="com6Btn" onClick={() => onButton()}>
                    <p className="com6BtnTxt">
                        소개서 읽어보기
                    </p>
                </div>


            </div>

        </div>
        

    </div>
}

export default Component06;