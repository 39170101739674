import React from 'react';
import TitleLogo from '../../../assets/icon/partner/logo.svg';
import ExitIcon from '../../../assets/icon/partner/exit_grey80.svg';
import RightIcon from '../../../assets/icon/partner/circle_angle_right_grey60.svg';
import '../partner.styles.css'
import { useNavigate } from 'react-router-dom';

const MenuComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const onInfo = () => {
        navigate("/");
    }

    const onSitter = () => {
        navigate("/sitter");
    }

    const onPartner = () => {
        navigate("/partner");
    }

    return <div className="menuScreen">
        <div className="header">
            <img src={TitleLogo} alt="우주펫 파트너" className="headerLogo"/>
            <img src={ExitIcon} alt="닫기" className="menu" onClick={() => onPartner()}/>
        </div>

        <div style={{height : "10.7448vh"}}/>

        <div className="menuButton" onClick={() => onInfo()}>
            <p className="menuTxt">우주펫 소개</p>
            <img src={RightIcon} alt='이동' className="menuIcon"/>
        </div>

        <div className="menuButton" onClick={() => onSitter()}>
            <p className="menuTxt">펫시터 지원하기</p>
            <img src={RightIcon} alt='이동' className="menuIcon"/>
        </div>

        <div className="menuButton" onClick={() => onPartner()}>
            <p className="menuTxt">파트너 등록하기</p>
            <img src={RightIcon} alt='이동' className="menuIcon"/>
        </div>
    
    </div>
}


export default MenuComponent;