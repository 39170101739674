import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';

const Component03 = ({...props}) : React.ReactElement => {

   
    return (
        <> 

            <div style={{height : "6.8966vh"}}/>

            <p className="grdientTxt02">
                파트너를 위한 혜택
            </p>

            <div style={{height : "1.7241vh"}}/>

            <p className="titleTxt02">
                우리 매장 상단 노출
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p className="contentTxt">
                파트너에 가입한 매장은 반려인들에게<br/>
                내 매장을 가장 상단에 보여줄 수 있어요.
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_003.png`} alt="" className="componentImg"/>
           
            
        </>

    );
}

export default Component03;