import React from "react";
import { CDN } from "../../../keys";
import '../partner.styles.css';


const Component04 = ({...props}) : React.ReactElement => {


    return <div style={{background : "#FFFFFF"}}>

        <div style={{height : 72}}/>

        <div className="componentCon">
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p className="webColorSubTitleTxt"> 
                    반려인들이 이야기한
                </p> 
                <div style={{height : 6}}/>
                <p className="webTitleTxt"> 
                    신뢰도 상승
                </p>                
                <div style={{height : 7}}/>
                <p className="webSubTitleTxt"> 
                    파트너 가입시 인증 뱃지를 지급해 드려요.<br/>
                    반려인들은 인증 뱃지가 있는 매장을<br/>
                    더 신뢰하고 있어요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_004.png`} className="componentImage" alt=""/>
        </div>
    
        <div style={{height : 72}}/>

    </div>
}

export default Component04;