import React from 'react';
import './partner.styles.css'
import PartnerHeader from './partner.header.component';
import Component01 from './mobile/component_01';
import Footer from './mobile/footer.component';
import Component02 from './mobile/component_02';
import Component03 from './mobile/component_03';
import Component04 from './mobile/component_04';
import Component05 from './mobile/component_05';
import Component07 from './mobile/component_07';
import Component06 from './mobile/component_06';
import ContentFooterComponent from './mobile/content_footer.component';

const PartnerMainMobileComponent = ({...props}) : React.ReactElement => {

    return <div className='screen'>
        <PartnerHeader />
        <Footer/>

        <Component01 />
        <Component02 />
        <Component03 />
        <Component04 />
        <Component05 />
        <Component06 />
        <Component07 />

        <ContentFooterComponent/>
    </div>
}


export default PartnerMainMobileComponent;